<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
        <v-card v-if="!dataLoaded">
          <v-card-title>Schedule Change Form (Loading...)</v-card-title>
          <v-card-text>Loading your current course schedule and any previously submitted course changes. This should only take a few seconds.</v-card-text>
        </v-card>
        <template v-else>
          <v-card>
            <v-toolbar>
              <v-toolbar-title>Schedule Change Status: {{ termLabel }}</v-toolbar-title>
            </v-toolbar>
          </v-card>
          <v-subheader>Submitted Changes</v-subheader>
          <v-list elevation="1">
            <course-entry v-for="item in changes" :key="item.crn" :item="item" :admin="true" :actionable="false"></course-entry>
          </v-list>
          <v-card>
            <v-card-text>
              <v-textarea v-model="comment" label="Add a message" style="margin-top:1em" rows="3" outlined hide-details></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="comment === ''" @click="addComment" color="info">Add Message</v-btn>
              <v-spacer></v-spacer>
              <v-btn to="/student/schedule-change/form" color="success">Make Additional Changes</v-btn>
            </v-card-actions>
          </v-card>
          <h3 style="margin-top:1em">Schedule Change Timeline</h3>
          <v-timeline v-if="messages.length > 0" dense style="margin:1em 1em 0 0">
            <v-timeline-item v-for="(message, index) in messages" :key="'message-'+index" :color="message.color || 'info'" :icon="message.icon || 'fal fa-comment'" :class="!(message.icon) && message.pidm !== user.pidm ? 'reverse-icon' : ''">
              <v-card @click="message.expanded = !message.expanded" style="cursor:pointer">
                <v-card-text :style="message.expanded ? 'padding-bottom:10px' : 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding-bottom:10px;'" v-html="message.text"></v-card-text>
                <v-card-text style="font-size:.8em;opacity:.7;padding-top:0">{{ message.name }} -- {{ stringFormatDate(message.date) }}</v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.v-timeline-item .v-timeline-item__inner-dot i.v-icon {
  font-size: 18px;
}
.v-timeline-item.reverse-icon .v-timeline-item__inner-dot i.v-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import { buildCourseBlock } from '@/components/student/schedule-change/functions'

export default {
  components: {
    CourseEntry: () => import('@/components/student/schedule-change/StudentCourseEntry')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const dataLoaded = ref(false)
    const term = ref('')
    const termLabel = ref('')
    const scDataId = ref('')
    const changes = ref([])
    const comment = ref('')
    const messages = ref([])

    onMounted(async () => {
      if (!('Student' in roles.value) && 'Employee' in roles.value) {
        root.$router.replace('/student/schedule-change/admin')
        return
      }
      let pidm = user.value.pidm
      // Get possible terms from the term warehouse
      let { data: termData } = await root.$feathers.service('system/term').find({ query: { end: { $gt: new Date() } } })
      let terms = []
      for (let i = 0; i < termData.length; i++) {
        terms.push(termData[i].term)
      }
      console.log(terms)
      // Get term from confirmation warehouse
      let { data: confData } = await root.$feathers.service('student/term-detail').find({ query: { pidm, term: { $in: terms } } })
      if (confData.length === 0) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'No confirmation record was found for you in any active term. Please contact the Records office for assistance.' })
        root.$router.replace('/')
        return
      } else {
        console.log(confData)
      }
      term.value = confData[0].term
      for (let i = 0; i < termData.length; i++) {
        if (termData[i].term === term.value) {
          termLabel.value = termData[i].label
        }
      }

      let { data: changeData } = await root.$feathers.service('student/schedule-change').find({ query: { term: term.value, pidm } })
      if (changeData.length > 0) {
        if ('status' in changeData[0] && changeData[0].status === 'returned') {
          root.$router.replace('/student/schedule-change/form')
          return
        }
        scDataId.value = changeData[0]._id
        if ('changes' in changeData[0]) {
          changeData[0].changes.forEach(async ({ crn, status, action }) => {
            if (status === 'processed') return
            let { data: classData } = await root.$feathers.service('calendar/classes').find({ query: { term: term.value, crn, $populate: 'instructors.directoryId' } })
            if (classData.length > 0) changes.value.push(buildCourseBlock({ ...classData[0], status, action }))
          })
        }
        if ('timeline' in changeData[0]) {
          changeData[0].timeline.forEach((comment) => messages.value.unshift({ ...comment, expanded: false }))
        }
      } else {
        root.$router.replace('/student/schedule-change/form')
      }
      dataLoaded.value = true
    })

    function addComment () {
      let timeline = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: comment.value,
        icon: 'fal fa-comment',
        color: 'info',
        visibleToStudent: true
      }
      root.$feathers.service('student/schedule-change').patch(scDataId.value, { $push: { timeline } }).then((data) => {
        if ('timeline' in data) {
          messages.value = []
          data.timeline.forEach((comment) => messages.value.unshift({ ...comment, expanded: false }))
          comment.value = ''
        }
      })
    }

    return {
      user,
      roles,
      dataLoaded,
      term,
      termLabel,
      changes,
      comment,
      messages,
      addComment,
      buildCourseBlock,
      stringFormatDate
    }
  }
}
</script>
